import { Component, OnInit, AfterViewInit } from '@angular/core';
import { ApiService } from '../services/api.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { LoadingScreenService } from "../services/loading-screen.service";
import { GlobalService } from '../services/global.service';
import { NavbarService } from '../services/navbar.service';

@Component({
  selector: 'app-landing',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.css'],

})
export class LandingComponent implements OnInit, AfterViewInit {
  products: any[];
  geolocationPosition;
  nearByCommunity;
  lat:any;
  lng:any;
  com_id:any;
  response:object;


  isbtn: any;
  constructor(
    private apiservice: ApiService,
    private router: Router,
    private globalService: GlobalService,
    private LoadingScreenService: LoadingScreenService,
    public nav: NavbarService
  ) {
    this.isbtn = 0;
    localStorage.clear();
    /** Loader Start */
    let loaderService = this.LoadingScreenService;
    loaderService.display(true);

    /*if (window.navigator && window.navigator.geolocation) {
      this.isbtn = 1;
      window.navigator.geolocation.getCurrentPosition(
        pos => {
          this.lng = +pos.coords.longitude;
          this.lat = +pos.coords.latitude;
          this.apiservice.nearbyCommunity(this.lat, this.lng).subscribe(data => {
            if (data['status_code'] != 200) {
              this.globalService.showError(data['status_message']);
            }
            this.response = data;
            let result = this.response['finalResult'];
            this.com_id = result.com_id;

             localStorage.setItem("lat", this.lat);
             localStorage.setItem("lng", this.lng);
             localStorage.setItem("ComID", this.com_id);
             localStorage.setItem("Region", result.region);
             localStorage.setItem("ComName", result.com_name);
             this.isbtn = 2;
            //this.router.navigate(['video-gallery/'+com_id])
            let loaderService = this.LoadingScreenService;
            loaderService.display(false);
          });
        },
        error => {
          this.apiservice.communityInfo(325).subscribe((data) => {
            if (data['status_code'] != 200) {
              this.globalService.showError(data['status_message']);
            }
            this.response = data;
            let result = this.response['finalResult']['info'];
            this.com_id = result.com_id;
            //console.log(result);

             localStorage.setItem("lat", result.latitude);
             localStorage.setItem("lng", result.longitude);
             localStorage.setItem("ComID", this.com_id);
             localStorage.setItem("Region", result.region_abbr);
             localStorage.setItem("ComName", result.com_name);
             this.isbtn = 2;
             let loaderService = this.LoadingScreenService;
             loaderService.display(false);
          });
          switch (error.code) {
            case 1:
              console.warn('Permission Denied');
              //this.globalService.showError('Permission Denied');
              break;
            case 2:
              console.warn('Position Unavailable');
              //this.globalService.showError('Position Unavailable');
              break;
            case 3:
              console.warn('Timeout');
              //this.globalService.showError('Timeout');
              break;
          }
        }
      );
    }; */

    /** Once Enabled above commented location remove following block of code */
    this.apiservice.communityInfo(1440).subscribe((data) => {
      if (data['status_code'] != 200) {
        this.globalService.showError(data['status_message']);
      }
      this.response = data;
      let result = this.response['finalResult']['info'];
      this.com_id = result.com_id;
      //console.log(result);
      /** LOCAL STORAGES */
       localStorage.setItem("lat", result.latitude);
       localStorage.setItem("lng", result.longitude);
       localStorage.setItem("ComID", this.com_id);
       localStorage.setItem("Region", result.region_abbr);
       localStorage.setItem("ComName", result.com_name);
       this.isbtn = 2;
       let loaderService = this.LoadingScreenService;
       loaderService.display(false);
    });
  }
  ngOnInit() {
    this.nav.hide();
    this.nav.doSomethingElseUseful();
  }
  ngAfterViewInit(): void {
    //throw new Error("Method not implemented.");
    /*let loaderService = this.LoadingScreenService;
    loaderService.display(false); */
  }
}





