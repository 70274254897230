import {
    transition,
    trigger,
    query,
    style,
    animate,
    group,
    animateChild
 } from '@angular/animations';

export const slideInAnimation =  
   trigger('routeAnimations', [
            transition('Home => *', [
                query(':enter, :leave', 
                    style({ position: 'fixed',  width: '100%' }), 
                    { optional: true }),
                group([
                    query(':enter', [
                        style({ transform: 'translateX(100%)' }), 
                        animate('0.5s ease', 
                        style({ transform: 'translateX(0%)' }))
                    ], { optional: true }),
                    query(':leave', [
                        style({ transform: 'translateX(0%)' }),
                        animate('0.5s ease', 
                        style({ transform: 'translateX(-100%)' }))
                        ], { optional: true }),
                ])
        ]),
        transition('Video-Gallery => *', [
             query(':enter, :leave', 
                  style({ position: 'fixed', width: '100%' }), 
                  { optional: true }),        
             group([
                  query(':enter',[
                      style({ transform: 'translateX(100%)' }),
                      animate('0.5s ease', 
                      style({ transform: 'translateX(0%)' }))
                  ], { optional: true }),
                  query(':leave', [
                      style({ transform:   'translateX(0%)'}),
                      animate('0.5s ease', 
                      style({ transform: 'translateX(-100%)' }))
                  ], { optional: true }),
             ])
        ]),
        transition('Auto-Complete => *', [
            query(':enter, :leave', 
                 style({ position: 'fixed', width: '100%' }), 
                 { optional: true }),        
            group([
                 query(':enter',[
                     style({ transform: 'translateX(100%)' }),
                     animate('0.2s ease', 
                     style({ transform: 'translateX(0%)' }))
                 ], { optional: true }),
                 query(':leave', [
                     style({ transform:   'translateX(0%)'}),
                     animate('0.2s ease', 
                     style({ transform: 'translateX(-100%)' }))
                 ], { optional: true }),
            ])
       ]), 
       transition('Search-City => *', [
            query(':enter, :leave', 
                    style({ position: 'fixed', width: '100%' }), 
                    { optional: true }),        
            group([
                    query(':enter',[
                        style({ transform: 'translateX(100%)' }),
                        animate('0.5s ease', 
                        style({ transform: 'translateX(0%)' }))
                    ], { optional: true }),
                    query(':leave', [
                        style({ transform:   'translateX(0%)'}),
                        animate('0.5s ease', 
                        style({ transform: 'translateX(-100%)' }))
                    ], { optional: true }),
            ])
        ]),      
        
        transition('Gallery => *', [  
          query(':enter, :leave', 
              style({ position: 'fixed', width: '100%' }), 
              { optional: true }),
          group([
              query(':enter', [
                  style({ transform: 'translateX(100%)' }),
                  animate('0.3s ease', 
                  style({ transform: 'translateX(0%)' }))
              ], { optional: true }),
              query(':leave', [
                  style({ transform: 'translateX(0%)' }),
                  animate('0.3s ease', 
                  style({ transform: 'translateX(-100%)' }))
              ], { optional: true }),
          ])
    ]),
     transition('Community-Details => *', [  
          query(':enter, :leave', 
              style({ position: 'fixed', width: '100%' }), 
              { optional: true }),
          group([
              query(':enter', [
                  style({ transform: 'translateX(100%)' }),
                  animate('0.5s ease', 
                  style({ transform: 'translateX(0%)' }))
              ], { optional: true }),
              query(':leave', [
                  style({ transform: 'translateX(0%)' }),
                  animate('0.5s ease', 
                  style({ transform: 'translateX(-100%)' }))
              ], { optional: true }),
          ])
    ]),
    transition('Home-List => *', [  
        query(':enter, :leave', 
            style({ position: 'fixed', width: '100%' }), 
            { optional: true }),
        group([
            query(':enter', [
                style({ transform: 'translateX(100%)' }),
                animate('0.2s ease', 
                style({ transform: 'translateX(0%)' }))
            ], { optional: true }),
            query(':leave', [
                style({ transform: 'translateX(0%)' }),
                animate('0.2s ease', 
                style({ transform: 'translateX(-100%)' }))
            ], { optional: true }),
        ])
  ]),

    transition('Model-Home-List => *', [  
        query(':enter, :leave', 
            style({ position: 'fixed', width: '100%' }), 
            { optional: true }),
        group([
            query(':enter', [
                style({ transform: 'translateX(100%)' }),
                animate('0.5s ease', 
                style({ transform: 'translateX(0%)' }))
            ], { optional: true }),
            query(':leave', [
                style({ transform: 'translateX(0%)' }),
                animate('0.5s ease', 
                style({ transform: 'translateX(-100%)' }))
            ], { optional: true }),
        ])
    ]),
    
    transition('Available-Homes-Detail => *', [  
        query(':enter, :leave', 
            style({ position: 'fixed', width: '100%' }), 
            { optional: true }),
        group([
            query(':enter', [
                style({ transform: 'translateX(100%)' }),
                animate('0.5s ease', 
                style({ transform: 'translateX(0%)' }))
            ], { optional: true }),
            query(':leave', [
                style({ transform: 'translateX(0%)' }),
                animate('0.5s ease', 
                style({ transform: 'translateX(-100%)' }))
            ], { optional: true }),
        ])
  ]),
  transition('Similar-Homes => *', [  
    query(':enter, :leave', 
        style({ position: 'fixed', width: '100%' }), 
        { optional: true }),
    group([
        query(':enter', [
            style({ transform: 'translateX(100%)' }),
            animate('0.5s ease', 
            style({ transform: 'translateX(0%)' }))
        ], { optional: true }),
        query(':leave', [
            style({ transform: 'translateX(0%)' }),
            animate('0.5s ease', 
            style({ transform: 'translateX(-100%)' }))
        ], { optional: true }),
    ])
]),
transition('Search-Country => *', [  
    query(':enter, :leave', 
        style({ position: 'fixed', width: '100%' }), 
        { optional: true }),
    group([
        query(':enter', [
            style({ transform: 'translateX(100%)' }),
            animate('0.5s ease', 
            style({ transform: 'translateX(0%)' }))
        ], { optional: true }),
        query(':leave', [
            style({ transform: 'translateX(0%)' }),
            animate('0.5s ease', 
            style({ transform: 'translateX(-100%)' })) 
        ], { optional: true }),
    ])
]), 
transition('Map => *', [  
    query(':enter, :leave', 
        style({ position: 'fixed', width: '100%' }), 
        { optional: true }),
    group([
        query(':enter', [
            style({ transform: 'translateX(100%)' }),
            animate('0.5s ease', 
            style({ transform: 'translateX(0%)' }))
        ], { optional: true }),
        query(':leave', [
            style({ transform: 'translateX(0%)' }),
            animate('0.5s ease', 
            style({ transform: 'translateX(-100%)' }))
        ], { optional: true }),
    ])
]),
transition('Home-Details => *', [  
    query(':enter, :leave', 
        style({ position: 'fixed', width: '100%' }), 
        { optional: true }),
    group([
        query(':enter', [
            style({ transform: 'translateX(100%)' }),
            animate('0.5s ease', 
            style({ transform: 'translateX(0%)' }))
        ], { optional: true }),
        query(':leave', [
            style({ transform: 'translateX(0%)' }),
            animate('0.5s ease', 
            style({ transform: 'translateX(-100%)' }))
        ], { optional: true }),
    ])
]),
transition('Contact => *', [
    query(':enter, :leave', 
         style({ position: 'fixed', width: '100%' }), 
         { optional: true }),        
    group([
         query(':enter',[
             style({ transform: 'translateX(100%)' }),
             animate('0.3s ease', 
             style({ transform: 'translateX(0%)' }))
         ], { optional: true }),
         query(':leave', [
             style({ transform:   'translateX(0%)'}),
             animate('0.3s ease', 
             style({ transform: 'translateX(-100%)' }))
         ], { optional: true }),
    ])
]), 
 transition('Success-Alert => *', [
    query(':enter, :leave', 
         style({ position: 'fixed', width: '100%' }), 
         { optional: true }),        
    group([
         query(':enter',[
             style({ transform: 'translateX(100%)' }),
             animate('0.3s ease', 
             style({ transform: 'translateX(0%)' }))
         ], { optional: true }),
         query(':leave', [
             style({ transform:   'translateX(0%)'}),
             animate('0.3s ease', 
             style({ transform: 'translateX(-100%)' }))
         ], { optional: true }),
    ])
]),
transition('Site => *', [
    query(':enter, :leave', 
         style({ position: 'fixed', width: '100%' }), 
         { optional: true }),        
    group([
         query(':enter',[
             style({ transform: 'translateX(100%)' }),
             animate('0.5s ease', 
             style({ transform: 'translateX(0%)' }))
         ], { optional: true }),
         query(':leave', [
             style({ transform:   'translateX(0%)'}),
             animate('0.5s ease', 
             style({ transform: 'translateX(-100%)' }))
         ], { optional: true }),
    ])
]),
transition('Nav-Bar => *', [
    query(':enter, :leave', 
         style({ position: 'fixed', width: '100%' }), 
         { optional: true }),        
    group([
         query(':enter',[
             style({ transform: 'translateX(100%)' }),
             animate('0.5s ease', 
             style({ transform: 'translateX(0%)' }))
         ], { optional: true }),
         query(':leave', [
             style({ transform:   'translateX(0%)'}),
             animate('0.5s ease', 
             style({ transform: 'translateX(-100%)' }))
         ], { optional: true }),
    ])
])   

    
]);