import { Component, OnInit, EventEmitter } from '@angular/core';
import Lity from 'lity';
import { ISlimScrollOptions, SlimScrollEvent } from 'ngx-slimscroll';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from '../services/api.service';
import { GlobalService } from '../services/global.service';
import { LoadingScreenService } from "../services/loading-screen.service";
import { NavbarService } from '../services/navbar.service';

@Component({
  selector: 'app-video-gallery',
  templateUrl: './video-gallery.component.html',
  styleUrls: ['./video-gallery.component.css']

})

export class VideoGalleryComponent implements OnInit {
  opts: ISlimScrollOptions;
  scrollEvents: EventEmitter<SlimScrollEvent>;

  ComId: number;
  ComName: string;
  Region: string;

  geolocationPosition;
  nearByCommunity;
  communityInfo;
  communityVideos;
  regionAbbr: String;
  overview: String;  

  constructor(
    private apiservice: ApiService,
    private _route: ActivatedRoute,
    private router: Router,
    private globalService: GlobalService,
    private LoadingScreenService: LoadingScreenService,
    public nav: NavbarService
  ) {
    /** Loader Start */
    let loaderService = this.LoadingScreenService;
    loaderService.display(true);

    localStorage.setItem("currentPage", this.router.url);
    this.ComId = Number(localStorage.getItem('ComID'));
    this.ComName = localStorage.getItem('ComName');
    this.Region = localStorage.getItem('Region');
  }
  ngOnInit() {

    /** Footer Menu Show */
    this.nav.show();

    this.scrollEvents = new EventEmitter<SlimScrollEvent>();

    this.play();
    if(this.ComId > 0){
      this.apiservice.communityInfo(this.ComId).subscribe((data) => {
        if (data['status_code'] != 200) {
          this.globalService.showError(data['status_message']);
        }
        this.communityInfo = data['finalResult'].info;
        this.communityVideos = data['finalResult'].videos;
        this.ComName = this.communityInfo['com_name'];
        this.regionAbbr = this.communityInfo['region_abbr'];
        this.overview = this.communityInfo['overview'].replace(/<[^>]+>/g, '');
        if((this.communityVideos).length <= 0){
          let loaderService = this.LoadingScreenService;
          loaderService.display(false);
        }
      });
    } else {
      var r = this.router;
      this.globalService.showError('Opps ! we could not get the community');
      setTimeout(function(){
        r.navigate(['/']);
      }, 3000);
    }
  }
  play(): void {
    let event = null;

    Promise.resolve()
      .then(() => this.timeout(3000))
      .then(() => {
        event = new SlimScrollEvent({
          type: 'scrollTo',
          duration: 2000,
          easing: 'linear'
        });

        this.scrollEvents.emit(event);
      })
      .then(() => this.timeout(3000))
      .then(() => {
        event = new SlimScrollEvent({
          type: 'scrollToTop',
          duration: 3000,
          easing: 'outCubic'
        });

        this.scrollEvents.emit(event);
      })
      .then(() => this.timeout(4000))
      .then(() => {
        event = new SlimScrollEvent({
          type: 'scrollToPercent',
          percent: 80,
          duration: 1000,
          easing: 'linear'

        });

        this.scrollEvents.emit(event);
      })
      .then(() => this.timeout(2000))
      .then(() => {
        event = new SlimScrollEvent({
          type: 'scrollTo',
          y: 200,
          duration: 4000,
          easing: 'inOutQuint'
        });

        this.scrollEvents.emit(event);
      });
  }

  timeout(ms: number): Promise<void> {
    return new Promise(resolve => setTimeout(() => resolve(), ms));
  }
  galleryBox(url: any) {
    Lity(url);
  }
  finalImageLoaded() {
    let loaderService = this.LoadingScreenService;
    loaderService.display(false);
  }
}
export interface ISlimScrollOptions {
  position?: string;
  barBackground?: string;
  barOpacity?: string;
  barWidth?: string;
  barBorderRadius?: string;
  barMargin?: string;
  gridBackground?: string;
  gridOpacity?: string;
  gridWidth?: string;
  gridBorderRadius?: string;
  gridMargin?: string;
  alwaysVisible?: boolean;
  visibleTimeout?: number;
}



