import { Component, OnInit, AfterViewInit } from '@angular/core';
import { ApiService } from '../services/api.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';

import { GlobalService } from '../services/global.service';
import { LoadingScreenService } from "../services/loading-screen.service";
import { NavbarService } from '../services/navbar.service';

@Component({
  selector: 'app-model-home-details',
  templateUrl: './model-home-details.component.html',
  styleUrls: ['./model-home-details.component.css']
})
export class ModelHomeDetailsComponent implements OnInit, AfterViewInit {

  ComId: number;
  ComName: string;
  Region: string;

  ModelId: any;
  designName: any;
  cityName: any;
  modelAddress: any;
  modelHoursOpen: any;
  modelDescription: any;
  modelPhone: any;

  /** Community Info */
  Overview: any;
  Phone: any;
  /** Community Info END */

  mhDetails: any;

  constructor(
    private apiservice: ApiService,
    private router: Router,
    private globalService: GlobalService,
    private LoadingScreenService: LoadingScreenService,
    public nav: NavbarService
  ) {
     /** Loader start */
     let loaderService = this.LoadingScreenService;
     loaderService.display(true);

     this.ComId = Number(localStorage.getItem('ComID'));
    this.ComName = localStorage.getItem('ComName');
    this.Region = localStorage.getItem('Region');
    this.ModelId = Number(localStorage.getItem('MHModelId'));
    var r = this.router;
    if (this.ComId > 0) {
      if (this.ModelId > 0) {
        this.apiservice.modelHomesOne(this.ComId, this.ModelId).subscribe(data => {
          if (data['status_code'] != 200) {
            this.globalService.showError('Failed to getting model homes details');
          }
          this.mhDetails = data['finalResult'];
          this.designName = data['finalResult']['design_name'];
          this.cityName = data['finalResult']['name'];
          this.modelAddress = data['finalResult']['model_address'];
          this.modelHoursOpen = data['finalResult']['model_hours_open'];
          this.modelDescription = (data['finalResult']['model_description']).replace(/<[^>]+>/g, '').replace(/&nbsp;/g, ' ');
          this.modelPhone = data['finalResult']['model_phone'];

          this.apiservice.communityContact(this.ComId).subscribe(Comdata => {
            this.Overview = (Comdata['finalResult'].overview).replace(/<[^>]+>/g, '').replace(/&nbsp;/g, ' ');
            this.Phone = ((Comdata['finalResult'].phone != '') ? Comdata['finalResult'].phone : 'N/A');

            /** Stop Loader */
            let loaderService = this.LoadingScreenService;
            loaderService.display(false);
          });
        });
      } else {
        this.globalService.showError('Opps! we could not get the modelhomes detail');
        setTimeout(function () {
          r.navigate(['home-list/']);
        }, 3000);
      }
    } else {
      this.globalService.showError('Opps! we could not get the community');
      setTimeout(function () {
        r.navigate(['/']);
      }, 3000);
    }
  }
  ngOnInit() {
    this.nav.show();
  }
  ngAfterViewInit(): void {

  }
}
