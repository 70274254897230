import { Component, OnInit, ViewEncapsulation, ViewChild } from '@angular/core';
import {FormControl} from '@angular/forms';
import {Observable} from 'rxjs';
import {map, startWith} from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from '../services/api.service';
import { MatInput } from '@angular/material/input';
import { LoadingScreenService } from "../services/loading-screen.service";
import { NavbarService } from '../services/navbar.service';

@Component({
  selector: 'app-autocomplete-comp',
  templateUrl: './autocomplete-comp.component.html',
  styleUrls: ['./autocomplete-comp.component.css']
})

export class AutocompleteCompComponent implements OnInit {
  myControl = new FormControl();
  options: any[] = [];
  filteredOptions: Observable<string[]>;
  /** VARIABLES */
  id: number;
  lat:any;
  lng:any;

  filterCountry: any;
  filterCity: any;

  allCommunities: any;

  prevPage: any;
  @ViewChild('communityFilter', { static: true }) communityFilterInput: MatInput;

  constructor(
    private apiservice: ApiService,
    private _route: ActivatedRoute,
    private router: Router,
    private LoadingScreenService: LoadingScreenService,
    public nav: NavbarService
  ) {

    /** Loader start */
    let loaderService = this.LoadingScreenService;
    loaderService.display(true);

    this.id = Number(localStorage.getItem('ComID'));
    this.lat = Number(localStorage.getItem('lat'));
    this.lng = Number(localStorage.getItem('lng'));

    var f_country = localStorage.getItem('filter_country');
    var f_city = localStorage.getItem('filter_city');
    this.filterCountry = ((f_country != '' && f_country != null) ? f_country : 'Choose a County');
    this.filterCity = ((f_city != '' && f_city != null) ? f_city : 'Choose a City');

    this.prevPage = localStorage.getItem("currentPage");

    this.apiservice.getCommunityList(f_city, f_country, this.lat, this.lng).subscribe(data => {
      this.options = data['finalResult'];

      /** Stop Loader */
      let loaderService = this.LoadingScreenService;
      loaderService.display(false);
      /** End */

       /** Autocomplete Input auto focus */
      this.communityFilterInput.focus();

      this.filteredOptions = this.myControl.valueChanges.pipe(
        startWith(''),
        map(value => this._filter(value))
      );
    });

  }

  ngOnInit() {
    this.nav.show();
  }
  onSelectionChange(event){

    /** Start Loader */
    let loaderService = this.LoadingScreenService;
    loaderService.display(true);

    localStorage.setItem('filter_community', event.option.value);
    this.apiservice.getCommunityByName(event.option.value).subscribe(data => {
      let result = data['finalResult'];
      console.log(result);
      /** LOCAL STORAGES */
       localStorage.setItem("ComID", result.com_id);
       localStorage.setItem("Region", result.region_abbr);
       localStorage.setItem("ComName", result.com_name);
       if(this.prevPage != '' && this.prevPage != null && this.prevPage != 'null' && this.prevPage != 'undefined'){
        this.router.navigate([this.prevPage]);
       } else {
        this.router.navigate(['community-list/']);
       }
    });

  }
  clearFilter(){

    /** Start Loader */
    let loaderService = this.LoadingScreenService;
    loaderService.display(true);

    localStorage.removeItem("filter_country");
    localStorage.removeItem("filter_city");

    this.filterCountry = "Choose a Country";
    this.filterCity = "Choose a City";
    this.apiservice.getCommunityList('', '', this.lat, this.lng).subscribe(data => {
      this.options = data['finalResult'];

      /** Stop Loader */
      let loaderService = this.LoadingScreenService;
      loaderService.display(false);

       /** Autocomplete Input auto focus */
       this.communityFilterInput.focus();

      this.filteredOptions = this.myControl.valueChanges.pipe(
        startWith(''),
        map(value => this._filter(value))
      );
    });
    this.router.navigate(['auto-complete/']);
  }
  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.options.filter(option => option.toLowerCase().indexOf(filterValue) === 0);
  }
}
