import { Component, OnInit, AfterViewInit } from '@angular/core';

import { ApiService } from '../services/api.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { ThrowStmt } from '@angular/compiler';

import { GlobalService } from '../services/global.service';
import { LoadingScreenService } from "../services/loading-screen.service";
import { NavbarService } from '../services/navbar.service';

@Component({
  selector: 'app-home-details',
  templateUrl: './home-details.component.html',
  styleUrls: ['./home-details.component.css']
})
export class HomeDetailsComponent implements OnInit, AfterViewInit {

  ComId: number;
  ComName: string;
  Region: string;

  ahGalleryImgs: any;

  LotId: any;
  MrDetails: string;
  Price: any;
  Bed: any;
  Bath: any;
  Par: any;
  SoldAlt: any;

  constructor(
    private apiservice: ApiService,
    private router: Router,
    private globalService: GlobalService,
    private LoadingScreenService: LoadingScreenService,
    public nav: NavbarService
  ) {
    /** Loader start */
    let loaderService = this.LoadingScreenService;
    loaderService.display(true);

    this.ComId = Number(localStorage.getItem('ComID'));
    this.ComName = localStorage.getItem('ComName');
    this.Region = localStorage.getItem('Region');

    /** Available Homes LocalStorage Varaiables */
    this.LotId = Number(localStorage.getItem('AHLotId'));
    this.MrDetails = localStorage.getItem('AHMrDetails');
    this.Price = localStorage.getItem('AHPrice');
    this.Bed = localStorage.getItem('AHBed');
    this.Bath = localStorage.getItem('AHBath');
    this.Par = localStorage.getItem('AHPar');
    this.SoldAlt = localStorage.getItem('AHSoldAlt');

    // Reset
    /* localStorage.removeItem('AHLotId');
    localStorage.removeItem('AHMrDetails');
    localStorage.removeItem('AHPrice');
    localStorage.removeItem('AHBed');
    localStorage.removeItem('AHBath');
    localStorage.removeItem('AHPar'); */
    /** Available Homes LocalStorage Variables END */
    var r = this.router;
    if (this.MrDetails != '') {
      if (this.ComId > 0) {
        if (this.LotId > 0) {
          this.apiservice.ahGallery(this.ComId, this.LotId).subscribe(data => {
            if (data['status_code'] != 200) {
              this.globalService.showError(data['status_message']);
            }
            this.ahGalleryImgs = data['finalResult'];
            if((this.ahGalleryImgs).length <= 0){
              let loaderService = this.LoadingScreenService;
              loaderService.display(false);
            }
          });
        } else {
          this.globalService.showError('Opps ! we could not get the lot');
          setTimeout(function () {
            r.navigate(['home-list/']);
          }, 3000);
        }
      } else {
        this.globalService.showError('Opps ! we could not get the community');
        setTimeout(function () {
          r.navigate(['/']);
        }, 3000);
      }

    }
  }

  ngOnInit() {
    this.nav.show();
  }
  finalImageLoaded() {
    let loaderService = this.LoadingScreenService;
    loaderService.display(false);
  }
  ngAfterViewInit(): void {

  }
}
