import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class GlobalService {

  constructor(
    private toastr:ToastrService
  ) {

   }
  showError(message) {
    this.toastr.error(message,'Failure');
  }
  showSuccess(message) {
    this.toastr.success(message,'Success');
  }
}
