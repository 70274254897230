import { Component, OnInit, HostListener } from "@angular/core";
import { ApiService } from "../services/api.service";
import { ActivatedRoute, Router } from "@angular/router";
import { Observable } from "rxjs";
import { GlobalService } from "../services/global.service";
import { LoadingScreenService } from "../services/loading-screen.service";
import { NavbarService } from "../services/navbar.service";
import { Options, LabelType } from "@angular-slider/ngx-slider";
import { MatCheckboxChange } from "@angular/material";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";

@Component({
  selector: "app-community-list",
  templateUrl: "./community-list.component.html",
  styleUrls: ["./community-list.component.css"],
})
export class CommunityListComponent implements OnInit {
  isShownPrice: boolean = false;
  isShownCity: boolean = false;
  isShownAmenities: boolean = false;
  isShownSales: boolean = false;
  isAjaxCompleted: boolean = false;

  cities: any;
  checkedCities: any[] = [];

  value: number = 100;
  highValue: number = 1600;
  options: Options = {
    floor: 100,
    ceil: 1600,
    translate: (value: number): string => {
      return "$" + value + "k";
    },
  };

  ComId: number;
  ComName: string;
  Region: string;
  totalCommunity: number;

  communities: any;

  showLoadingIndicator = true;

  lat: any;
  lng: any;

  name = "Set iframe source";
  url: string = "https://ivoryhomes.com/ifram_location?minAmt=" +
  this.value +
  "&maxAmt=" +
  this.highValue +
  "&cities=" +
  this.checkedCities.toString();
  urlSafe: SafeResourceUrl;

  togglePrice() {
    this.isShownPrice = !this.isShownPrice;
  }
  priceHide() {
    this.isShownPrice = false;
  }
  toggleCity() {
    this.isAjaxCompleted = false;
    let loaderService = this.LoadingScreenService;
    loaderService.display(true);
    this.apiservice.getCityByCommunity().subscribe((data) => {
      if (data["status_code"] != 200) {
        this.globalService.showError(data["status_message"]);
      }
      this.cities = data["finalResult"];
      this.isShownCity = !this.isShownCity;
      loaderService.display(false);
      if (this.cities.length <= 0) {
        let loaderService = this.LoadingScreenService;
        loaderService.display(false);
      }
      this.isAjaxCompleted = true;
    });
  }
  cityHide() {
    this.isShownCity = false;
  }
  toggleAmenities() {
    this.isShownAmenities = !this.isShownAmenities;
  }
  amenitiesHide() {
    this.isShownAmenities = false;
  }
  toggleSales() {
    this.isShownSales = !this.isShownSales;
  }
  salesHide() {
    this.isShownSales = false;
  }

  clickCity(event: MatCheckboxChange, city_id) {
    this.isAjaxCompleted = false;
    if (event.checked == true) {
      this.checkedCities.push(city_id);
    } else {
      const index = this.checkedCities.indexOf(city_id);
      if (index > -1) {
        // only splice array when item is found
        this.checkedCities.splice(index, 1); // 2nd parameter means remove one item only
      }
    }

  this.url =  "https://ivoryhomes.com/ifram_location?minAmt=" +
  this.value +
  "&maxAmt=" +
  this.highValue +
  "&cities=" +
  this.checkedCities.toString();
  this.urlSafe = this.sanitizer.bypassSecurityTrustResourceUrl(this.url);

    var f_country = localStorage.getItem("filter_country");
    let loaderService = this.LoadingScreenService;
    loaderService.display(true);

    this.apiservice
      .communityListNew(
        this.checkedCities,
        this.value,
        this.highValue,
        f_country,
        this.lat,
        this.lng
      )
      .subscribe((data) => {
        if (data["status_code"] != 200) {
          this.globalService.showError(data["status_message"]);
        }
        this.totalCommunity = data["finalResult"]["total"];
        this.communities = data["finalResult"]["data"];
        loaderService.display(false);
        if (this.communities.length <= 0) {
          let loaderService = this.LoadingScreenService;
          loaderService.display(false);
        }
        this.isAjaxCompleted = true;
      });
  }

  priceChange() {
    this.isAjaxCompleted = false;
    var f_country = localStorage.getItem("filter_country");
    let loaderService = this.LoadingScreenService;
    loaderService.display(true);
    this.apiservice
      .communityListNew(
        this.checkedCities,
        this.value,
        this.highValue,
        f_country,
        this.lat,
        this.lng
      )
      .subscribe((data) => {
        if (data["status_code"] != 200) {
          this.globalService.showError(data["status_message"]);
        }
        this.totalCommunity = data["finalResult"]["total"];
        this.communities = data["finalResult"]["data"];
        loaderService.display(false);
        if (this.communities.length <= 0) {
          let loaderService = this.LoadingScreenService;
          loaderService.display(false);
        }
        this.isAjaxCompleted = true;
      });
  }

  constructor(
    private apiservice: ApiService,
    private router: Router,
    private globalService: GlobalService,
    private LoadingScreenService: LoadingScreenService,
    public nav: NavbarService,
    public sanitizer: DomSanitizer
  ) {
    /** Loader Start */
    let loaderService = this.LoadingScreenService;
    loaderService.display(true);
    localStorage.setItem("currentPage", this.router.url);
    this.ComId = Number(localStorage.getItem("ComID"));
    this.ComName = localStorage.getItem("ComName");
    this.Region = localStorage.getItem("Region");

    this.lat = Number(localStorage.getItem("lat"));
    this.lng = Number(localStorage.getItem("lng"));

    var f_country = localStorage.getItem("filter_country");
    var f_city = localStorage.getItem("filter_city");

    if (this.ComId > 0) {
      this.apiservice
        .communityListNew(
          this.checkedCities,
          this.value,
          this.highValue,
          f_country,
          this.lat,
          this.lng
        )
        .subscribe((data) => {
          if (data["status_code"] != 200) {
            this.globalService.showError(data["status_message"]);
          }
          this.totalCommunity = data["finalResult"]["total"];
          this.communities = data["finalResult"]["data"];
          loaderService.display(false);
          if (this.communities.length <= 0) {
            let loaderService = this.LoadingScreenService;
            loaderService.display(false);
          }
        });
    } else {
      var r = this.router;
      this.globalService.showError("Opps ! we could not get the community");
      setTimeout(function () {
        r.navigate(["/"]);
      }, 3000);
    }
  }
  communityDetail(ComID, ComName, Region) {
    localStorage.setItem("ComID", ComID);
    localStorage.setItem("ComName", ComName);
    localStorage.setItem("Region", Region);
    var r = this.router;
    r.navigate(["/community-details"]);
  }

  ngOnInit() {
    this.nav.hide();
    this.urlSafe = this.sanitizer.bypassSecurityTrustResourceUrl(this.url);
  }
}
