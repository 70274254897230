import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { VideoGalleryComponent } from './video-gallery/video-gallery.component';
import { AutocompleteCompComponent } from './autocomplete-comp/autocomplete-comp.component';
import { LandingComponent } from './landing/landing.component';
import { SearchByCityComponent } from './search-by-city/search-by-city.component';
import { NavBarComponent } from './nav-bar/nav-bar.component';
import { CommunityListComponent } from './community-list/community-list.component';
import { CommunityFilterComponent } from './community-filter/community-filter.component';
import { CommunityDetailsComponent } from './community-details/community-details.component';
import { HomeListComponent } from './home-list/home-list.component';
import { ModelHomesComponent } from './model-homes/model-homes.component';
import { AvailableHomesDetailComponent } from './available-homes-detail/available-homes-detail.component';
import { SimilarHomesComponent } from './similar-homes/similar-homes.component';
import { GalleryComponent } from './gallery/gallery.component';
import { SearchByCountryComponent } from './search-by-country/search-by-country.component';
import { HomeDetailsComponent } from './home-details/home-details.component';
import { ModelHomeGalleryComponent } from './model-home-gallery/model-home-gallery.component';
import { ModelHomeDetailsComponent } from './model-home-details/model-home-details.component';
import { MapComponent } from './map/map.component';
import { ContactComponent } from './contact/contact.component';
import { SuccessAlertComponent } from './success-alert/success-alert.component';
import { NotebookComponent } from './notebook/notebook.component';
import { NotFoundComponentComponent } from './not-found-component/not-found-component.component';

const appRoutes: Routes = [
  { path: '',
    redirectTo: '/landing',
    pathMatch: 'full'
  },
  { path: 'landing', component: LandingComponent,data: {animation: 'Home'} },
  { path: 'video-gallery', component: VideoGalleryComponent,data: {animation: 'Video-Gallery'} },
  { path: 'auto-complete', component: AutocompleteCompComponent,data: {animation: 'Auto-Complete'} },
  { path: 'searchby-city', component: SearchByCityComponent,data: {animation: 'Search-City'} },
  { path: 'search-by-country', component: SearchByCountryComponent,data: {animation: 'Search-Country'} },
  { path: 'nav-bar', component: NavBarComponent,data: {animation: 'Nav-Bar'}  },
  { path: 'community-list', component: CommunityListComponent,data: {animation: 'Community-Details'} },
  { path: 'community-filter', component: CommunityFilterComponent,data: {animation: 'Home'} },
  { path: 'community-details', component: CommunityDetailsComponent,data: {animation: 'Home'} },
  { path: 'home-list', component: HomeListComponent,data: {animation: 'Home-List'} },
  { path: 'model-homes-list', component: ModelHomesComponent,data: {animation: 'Model-Home-List'} },
  { path: 'model-homes-gallery', component: ModelHomeGalleryComponent,data: {animation: 'Home-Details'} },
  { path: 'model-homes-detail', component: ModelHomeDetailsComponent,data: {animation: 'Home-Details'} },
  { path: 'available-homes-detail', component: AvailableHomesDetailComponent,data: {animation: 'Available-Homes-Detail'} },
  { path: 'similar-homes', component: SimilarHomesComponent,data: {animation: 'Similar-Homes'} },
  { path: 'gallery', component: GalleryComponent,data: {animation: 'Gallery'} },
  { path: 'home-details', component: HomeDetailsComponent,data: {animation: 'Home-Details'} },
  { path: 'map', component: MapComponent,data: {animation: 'Map'} },
  { path: 'site', component: NotebookComponent,data: {animation: 'Site'} },
  { path: 'contact', component: ContactComponent,data: {animation: 'Contact'} },
  { path: 'success-alert', component: SuccessAlertComponent,data: {animation: 'Success-Alert'} },
  { path: '404', component: NotFoundComponentComponent},
  { path: '**', redirectTo: '/404'}

];

@NgModule({
  imports: [RouterModule.forRoot(appRoutes

    )],
  exports: [RouterModule]
})
export class AppRoutingModule { }


