import { Component, OnInit, AfterViewInit } from '@angular/core';
import { ApiService } from '../services/api.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';

import { GlobalService } from '../services/global.service';
import { LoadingScreenService } from "../services/loading-screen.service";
import { NavbarService } from '../services/navbar.service';

@Component({
  selector: 'app-available-homes-detail',
  templateUrl: './available-homes-detail.component.html',
  styleUrls: ['./available-homes-detail.component.css']
})
export class AvailableHomesDetailComponent implements OnInit, AfterViewInit {

  ComId: number;
  ComName: string;
  Region: string;

  LotId: any;

  /** Community Info */
  Overview: any;
  Phone: any;
  /** Community Info END */

  ahDetails: any;
  MrDetails: any;
  Price: any;
  LotTotSqFt: any;
  LotFinSqFt: any;
  Bed: any;
  Bath: any;
  Par: any;
  ahRegion: any;
  designStyle: any;
  housePlan: any;
  soldAlt: any;

  constructor(
    private apiservice: ApiService,
    private router: Router,
    private globalService: GlobalService,
    private LoadingScreenService: LoadingScreenService,
    public nav: NavbarService
  ) {

    /** Loader start */
    let loaderService = this.LoadingScreenService;
    loaderService.display(true);

    this.ComId = Number(localStorage.getItem('ComID'));
    this.ComName = localStorage.getItem('ComName');
    this.Region = localStorage.getItem('Region');

    this.LotId = Number(localStorage.getItem('AHLotId'));
    var r = this.router;
    if (this.ComId > 0) {
      if (this.LotId > 0) {
        this.apiservice.moveInreadyOne(this.ComId, this.LotId).subscribe(data => {
          if (data['status_code'] != 200) {
            this.globalService.showError('Failed to getting available homes details');
          }
          this.ahDetails = data['finalResult'];
          this.MrDetails = this.ahDetails['mr_details'];
          this.Price = this.ahDetails['price'];
          this.LotTotSqFt = this.ahDetails['lot_total_sqft'];
          this.LotFinSqFt = this.ahDetails['lot_finished_sqft'];
          this.Bed = this.ahDetails['lot_bedrooms'];
          this.Bath = this.ahDetails['lot_bathrooms'];
          this.Par = this.ahDetails['car'];
          this.ahRegion = (this.ahDetails['region']).replace(/&nbsp;/g, ' ');
          this.designStyle = this.ahDetails['design_style'];
          this.housePlan = this.ahDetails['lot_house_plan'];
          this.soldAlt = this.ahDetails['sold_alt'];

          this.apiservice.communityContact(this.ComId).subscribe(Comdata => {
            this.Overview = (Comdata['finalResult'].overview).replace(/<[^>]+>/g, '').replace(/&nbsp;/g, ' ');
            this.Phone = ((Comdata['finalResult'].phone != '') ? Comdata['finalResult'].phone : 'N/A');

            /** Stop Loader */
            let loaderService = this.LoadingScreenService;
            loaderService.display(false);
          });
        });
      } else {
        this.globalService.showError('Opps! we could not get the lot');
        setTimeout(function () {
          r.navigate(['home-list/']);
        }, 3000);
      }
    } else {
      this.globalService.showError('Opps! we could not get the community');
      setTimeout(function () {
        r.navigate(['/']);
      }, 3000);
    }
  }
  ngOnInit() {
    this.nav.show();
  }
  ngAfterViewInit(): void {

  }
}
