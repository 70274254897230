 import { Component, OnInit, ViewEncapsulation, ViewChild } from '@angular/core';
import {FormControl} from '@angular/forms';
import { ApiService } from '../services/api.service';
import { ActivatedRoute, Router } from '@angular/router';
import {Observable} from 'rxjs';
import {map, startWith} from 'rxjs/operators';

import { LoadingScreenService } from "../services/loading-screen.service";
import { MatInput } from '@angular/material/input';
import { NavbarService } from '../services/navbar.service';

@Component({
  selector: 'app-search-by-city',
  templateUrl: './search-by-city.component.html',
  styleUrls: ['./search-by-city.component.css']


})

/** export class SearchByCityComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

} **/

export class SearchByCityComponent implements OnInit {
  myControl = new FormControl();
  options: string[] = [];
  filteredOptions: Observable<string[]>;

  ComId: number;
  ComName: string;
  Region: string;

  @ViewChild('cityFilter', { static: true }) cityFilterInput: MatInput;

  filterCountry: any;
  filterCity: any;

  constructor(
    private apiservice: ApiService,
    private router: Router,
    private LoadingScreenService: LoadingScreenService,
    public nav: NavbarService
  ) {
    /** Loader start */
    let loaderService = this.LoadingScreenService;
    loaderService.display(true);

    var f_country = localStorage.getItem('filter_country');
    var f_city = localStorage.getItem('filter_city');
    this.filterCountry = ((f_country != '' && f_country != null) ? f_country : 'Choose a County');
    this.filterCity = ((f_city != '' && f_city != null) ? f_city : 'Choose a City');

    this.ComId = Number(localStorage.getItem('ComID'));
    this.ComName = localStorage.getItem('ComName');
    this.Region = localStorage.getItem('Region');

    this.apiservice.getCity().subscribe(data => {
      this.options = data['finalResult'];

      /** Stop Loader */
      let loaderService = this.LoadingScreenService;
      loaderService.display(false);
      /** End */

      /** Autocomplete Input auto focus */
      this.cityFilterInput.focus();

      this.filteredOptions = this.myControl.valueChanges.pipe(
        startWith(''),
        map(value => this._filter(value))
      );
    });
  }

  ngOnInit() {
    this.nav.show();
  }
  onSelectionChange(event){
    localStorage.setItem('filter_city', event.option.value);
    this.router.navigate(['auto-complete/']);
  }
  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.options.filter(option => option.toLowerCase().indexOf(filterValue) === 0);
  }
}

