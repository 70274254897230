import { Component, OnInit, AfterViewInit } from '@angular/core';
import { LoadingScreenService } from "../services/loading-screen.service";
@Component({
  selector: 'app-not-found-component',
  templateUrl: './not-found-component.component.html',
  styleUrls: ['./not-found-component.component.css']
})

export class NotFoundComponentComponent implements OnInit, AfterViewInit {

  constructor(private LoadingScreenService: LoadingScreenService) {
    /** Start Loader */
    let loaderService = this.LoadingScreenService;
    loaderService.display(false);
  }

  ngOnInit() {

  }
  ngAfterViewInit(): void {

  }

}
