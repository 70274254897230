import { AfterViewInit, Component, OnInit } from '@angular/core';

import { ApiService } from '../services/api.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { ThrowStmt } from '@angular/compiler';

import { GlobalService } from '../services/global.service';
import { LoadingScreenService } from "../services/loading-screen.service";
import { NavbarService } from '../services/navbar.service';

@Component({
  selector: 'app-model-home-gallery',
  templateUrl: './model-home-gallery.component.html',
  styleUrls: ['./model-home-gallery.component.css']
})
export class ModelHomeGalleryComponent implements OnInit, AfterViewInit {

  ComId: number;
  ComName: string;
  Region: string;

  mhGalleryImgs: any;

  ModelId: any;
  designName: any;
  cityName: any;
  modelAddress: any;
  modelHoursOpen: any;

  constructor(
    private apiservice: ApiService,
    private router: Router,
    private globalService: GlobalService,
    private LoadingScreenService: LoadingScreenService,
    public nav: NavbarService
  ) {
    /** Loader start */
    let loaderService = this.LoadingScreenService;
    loaderService.display(true);

    this.ComId = Number(localStorage.getItem('ComID'));
    this.ComName = localStorage.getItem('ComName');
    this.Region = localStorage.getItem('Region');

    /** Available Homes LocalStorage Varaiables */
    this.ModelId = Number(localStorage.getItem('MHModelId'));

    var r = this.router;
      if (this.ComId > 0) {
        if (this.ModelId > 0) {
          this.apiservice.modelHomesGallery(this.ComId, this.ModelId).subscribe(data => {
            console.log(data);
            if (data['status_code'] != 200) {
              this.globalService.showError(data['status_message']);
            }

            this.mhGalleryImgs = data['finalResult']['gallery'];
            this.designName = data['finalResult']['design_name'];
            this.cityName = data['finalResult']['name'];
            this.modelAddress = data['finalResult']['model_address'];
            this.modelHoursOpen = data['finalResult']['model_hours_open'];
            if(typeof this.mhGalleryImgs != 'undefined' && (this.mhGalleryImgs).length <= 0){
              let loaderService = this.LoadingScreenService;
              loaderService.display(false);
            }
          });
        } else {
          this.globalService.showError('Opps ! we could not get the modelhomes galleries');
          setTimeout(function () {
            r.navigate(['home-list/']);
          }, 3000);
        }
      } else {
        this.globalService.showError('Opps ! we could not get the community');
        setTimeout(function () {
          r.navigate(['/']);
        }, 3000);
      }
   }
   ngOnInit() {
    this.nav.show();
  }
  finalImageLoaded() {
    let loaderService = this.LoadingScreenService;
    loaderService.display(false);
  }
  ngAfterViewInit(): void {

  }

}
