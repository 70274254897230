import { Component } from '@angular/core';
import { Router, NavigationStart, NavigationEnd,
  NavigationCancel, NavigationError, Event } from '@angular/router';
import {
  trigger,
  state,
  style,
  animate,
  transition,
  // ...
} from '@angular/animations';
import { slideInAnimation } from './router-animation';
import { LoadingScreenService } from "./services/loading-screen.service";
import { Subscription } from "rxjs";

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.css'],
  animations: [ slideInAnimation ]
})
export class AppComponent {
  centered = false;
  disabled = false;
  unbounded = false;

  radius: number;
  color: string;
  title = 'Ivory-Tour';
  //for the spinner
  showLoader: boolean = true;
  loadingSubscription: Subscription;
  //LoaderService is for the spinner
  constructor(private loaderService: LoadingScreenService) {

   }
  //for the spinner
  ngOnInit() {
    this.loadingSubscription = this.loaderService.loadingStatus.subscribe((val: boolean) => {
      this.showLoader = val;
      //console.log("Response showLoader - "+this.showLoader);
    });
  }
  ngOnDestroy() {
    this.loadingSubscription.unsubscribe();
  }
}


