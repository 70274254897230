import { Component, OnInit, AfterViewInit } from '@angular/core';
import { ApiService } from '../services/api.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';

import { GlobalService } from '../services/global.service';
import { LoadingScreenService } from "../services/loading-screen.service";
import { NavbarService } from '../services/navbar.service';

@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.css']
})
export class MapComponent implements OnInit, AfterViewInit {

  ComId: number;
  ComName: string;
  Region: string;

  mapWidget: any;

  loadAPI: Promise<any>;

  constructor(
    private apiservice: ApiService,
    private router: Router,
    private globalService: GlobalService,
    private LoadingScreenService: LoadingScreenService,
    public nav: NavbarService
  ) {
    /** Loader start */
    let loaderService = this.LoadingScreenService;
    loaderService.display(true);

    localStorage.setItem("currentPage", this.router.url); /** for set current Page url for filter navigation */

    this.ComId = Number(localStorage.getItem('ComID'));
    this.ComName = localStorage.getItem('ComName');
    this.Region = localStorage.getItem('Region');
    var r = this.router;
    if(this.ComId > 0) {
      this.apiservice.communityMap(this.ComId).subscribe(data => {
        if (data['status_code'] != 200) {
          this.globalService.showError('Failed to getting map');
        }
        this.mapWidget = data['finalResult'].map_widget;
        if (this.mapWidget) {
          var matches = this.mapWidget.substring(
            this.mapWidget.lastIndexOf('c=') + 1,
            this.mapWidget.lastIndexOf(".js")
          );
          var url = matches.substring(2) + '.js';
          this.loadAPI = new Promise((resolve) => {
            this.loadScript(url);
            resolve(true);
          });
        } else {
          this.mapWidget = [];
        }
        /** Stop Loader */
        let loaderService = this.LoadingScreenService;
        loaderService.display(false);
      });
    } else {
      this.globalService.showError('Opps! we could not get the community');
      setTimeout(function () {
        r.navigate(['/']);
      }, 3000);
    }
   }
  public loadScript(url: any) {
    var isFound = false;
    var scripts = document.getElementsByTagName("script")
    for (var i = 0; i < scripts.length; ++i) {
      if (scripts[i].getAttribute('src') != null && scripts[i].getAttribute('src').includes("loader")) {
        isFound = true;
      }
    }

    if (!isFound) {
      var dynamicScripts = [url];
      for (var i = 0; i < dynamicScripts.length; i++) {
        let node = document.createElement('script');
        node.src = dynamicScripts[i];
        node.type = 'text/javascript';
        node.async = false;
        node.charset = 'utf-8';
        document.getElementsByTagName('head')[0].appendChild(node);
      }
    }
  }
  ngOnInit() {
    this.nav.show();
  }
  ngAfterViewInit(): void {

  }

}
