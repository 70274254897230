import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
@Injectable({
  providedIn: 'root'
})
export class ApiService {
  constructor(
    private http: HttpClient
  ) { }
  private REST_API_SERVER = "https://ivoryhub.co/iv_tour_api/index.php?";
  auth() {
    var URI  = this.REST_API_SERVER + "method=Auth";

    return this
            .http
            .get(`${URI}`);
  }
  nearbyCommunity(lat, lang) {
    var URI  = this.REST_API_SERVER + "method=nearbyCom&lat=" + lat + "&lang=" + lang;
    var res =  this
            .http
            .get(`${URI}`);
    return res;
  }
  nearbyAllCommunity(lat, lang) {
    var URI  = this.REST_API_SERVER + "method=all_community&lat=" + lat + "&lang=" + lang;
    var res =  this
            .http
            .get(`${URI}`);
    return res;
  }
  communityInfo(id: number){
    var URI  = this.REST_API_SERVER + "method=communityInfo&id=" + id;
    return this
            .http
            .get(`${URI}`);
  }
  communityContact(id: number){
    var URI  = this.REST_API_SERVER + "method=communityContact&id=" + id;
    return this
            .http
            .get(`${URI}`);
  }
  communityMap(id: number){
    var URI  = this.REST_API_SERVER + "method=communityMap&id=" + id;
    return this
            .http
            .get(`${URI}`);
  }
  moveInready(id){
    var URI  = this.REST_API_SERVER + "method=moveInready&id=" + id;
    return this
            .http
            .get(`${URI}`);
  }
  moveInreadyOne(id, lot_id: any){
    var URI  = this.REST_API_SERVER + "method=moveInreadyOne&id=" + id+"&lot_id="+lot_id;
    return this
            .http
            .get(`${URI}`);
  }
  ahGallery(id: any, lot_id: any){
    var URI  = this.REST_API_SERVER + "method=ahGallery&id=" + id +"&lot_id="+lot_id;
    return this
            .http
            .get(`${URI}`);
  }
  homeDesigns(id){
    var URI  = this.REST_API_SERVER + "method=homeDesigns&id=" + id;
    return this
            .http
            .get(`${URI}`);
  }
  noteBook(id){
    var URI  = this.REST_API_SERVER + "method=noteBook&id=" + id;
    return this
            .http
            .get(`${URI}`);
  }
  gallery(id){
    var URI  = this.REST_API_SERVER + "method=gallery&id=" + id;
    return this
            .http
            .get(`${URI}`);
  }
  getCountry(){
    var URI  = this.REST_API_SERVER + "method=countryList";
    return this
            .http
            .get(`${URI}`);
  }
  getCity(){
    var URI  = this.REST_API_SERVER + "method=cityList";
    return this
            .http
            .get(`${URI}`);
  }
  getCommunityList(city, country, lat, lang){
    var URI  = this.REST_API_SERVER + "method=communityList&lat=" + lat + "&lang=" + lang+"&city="+city+"&country="+country;
    return this
            .http
            .get(`${URI}`);
  }
  getCommunityByName(name){
    var URI  = this.REST_API_SERVER + "method=communityByname&name=" + name;
    return this
            .http
            .get(`${URI}`);
  }
  contact(formData){
    var header = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json'})
    }
    var URI  = this.REST_API_SERVER + "method=contact&name="+formData.name+"&email="+formData.email+"&phone="+formData.phone+"&message="+formData.message+"";
    return this
            .http
            .get(URI);
  }

  modelHomes(id){
    var URI  = this.REST_API_SERVER + "method=modelHomes&id=" + id;
    return this
            .http
            .get(`${URI}`);
  }
  modelHomesGallery(id, lot_id: any){
    var URI  = this.REST_API_SERVER + "method=modelHomesGallery&id=" + id+"&model_id="+lot_id;
    return this
            .http
            .get(`${URI}`);
  }
  modelHomesOne(id, lot_id: any){
    var URI  = this.REST_API_SERVER + "method=modelHomesOne&id=" + id+"&model_id="+lot_id;
    return this
            .http
            .get(`${URI}`);
  }
  communityListNew(city = [], minAmt, maxAmt, country, lat, lang){
    var city_q = (city.length > 0) ? city.toString() : "";
    var URI  = this.REST_API_SERVER + "method=communityListNew&lat=" + lat + "&lang=" + lang+"&city="+city_q+"&country="+country+"&minAmt="+minAmt+"&maxAmt="+maxAmt;
    return this
            .http
            .get(`${URI}`);
  }
  getCityByCommunity(){
    var URI  = this.REST_API_SERVER + "method=getCityByCommunity";
    return this
            .http
            .get(`${URI}`);
  }
}
