import { Component, OnInit } from '@angular/core';
import { ApiService } from '../services/api.service';
import { Observable } from 'rxjs';
import { Event, Router, NavigationStart, NavigationEnd } from '@angular/router';

import { GlobalService } from '../services/global.service';
import { LoadingScreenService } from "../services/loading-screen.service";
import { NavbarService } from '../services/navbar.service';

@Component({
  selector: 'app-home-list',
  templateUrl: './home-list.component.html',
  styleUrls: ['./home-list.component.css']
})
export class HomeListComponent implements OnInit {

  ComId: number;
  ComName: string;
  Region: string;

  availableHomes: any;

  showLoadingIndicator = true;

  constructor(
    private apiservice: ApiService,
    private router: Router,
    private globalService: GlobalService,
    private LoadingScreenService: LoadingScreenService,
    public nav: NavbarService
  ) {

    /** Loader Start */
    let loaderService = this.LoadingScreenService;
    loaderService.display(true);

    /*this.router.events.subscribe((routerEvent: Event) => {
      if (routerEvent instanceof NavigationStart) {
        this.showLoadingIndicator = true;
      }
      if (routerEvent instanceof NavigationEnd) {
        this.showLoadingIndicator = false;
      }
    });*/
    localStorage.setItem("currentPage", this.router.url);
    this.ComId = Number(localStorage.getItem('ComID'));
    this.ComName = localStorage.getItem('ComName');
    this.Region = localStorage.getItem('Region');

    if(this.ComId > 0){
      this.apiservice.moveInready(this.ComId).subscribe(data => {
        if (data['status_code'] != 200) {
          this.globalService.showError(data['status_message']);
        }
        this.availableHomes = data['finalResult'];
        if((this.availableHomes).length <= 0){
          let loaderService = this.LoadingScreenService;
          loaderService.display(false);
        }
      });
    } else {
      var r = this.router;
      this.globalService.showError('Opps ! we could not get the community');
      setTimeout(function(){
        r.navigate(['/']);
      }, 3000);
    }

  }
  availHomesGallery(LotId: any, MrDetails: string, Price: any, Bed: any, Bath: any, Par: any, SoldAlt: any) {
    localStorage.setItem('AHLotId', LotId);
    localStorage.setItem('AHMrDetails', MrDetails);
    localStorage.setItem('AHPrice', Price);
    localStorage.setItem('AHBed', Bed);
    localStorage.setItem('AHBath', Bath);
    localStorage.setItem('AHPar', Par);
    localStorage.setItem("AHSoldAlt", SoldAlt);
    this.router.navigate(['home-details/']);
  }
  ngOnInit() {
    this.nav.show();
  }
  finalImageLoaded() {
    let loaderService = this.LoadingScreenService;
    loaderService.display(false);
  }
}
