import { Component, OnInit } from '@angular/core';
import { ApiService } from '../services/api.service';
import { Observable } from 'rxjs';
import { Event, Router, NavigationStart, NavigationEnd } from '@angular/router';

import { GlobalService } from '../services/global.service';
import { LoadingScreenService } from "../services/loading-screen.service";
import { NavbarService } from '../services/navbar.service';

@Component({
  selector: 'app-model-homes',
  templateUrl: './model-homes.component.html',
  styleUrls: ['./model-homes.component.css']
})
export class ModelHomesComponent implements OnInit {

  ComId: number;
  ComName: string;
  Region: string;

  modelHomes: any;

  showLoadingIndicator = true;

  constructor(
    private apiservice: ApiService,
    private router: Router,
    private globalService: GlobalService,
    private LoadingScreenService: LoadingScreenService,
    public nav: NavbarService) {
      /** Loader Start */
      let loaderService = this.LoadingScreenService;
      loaderService.display(true);
      localStorage.setItem("currentPage", this.router.url);
      this.ComId = Number(localStorage.getItem('ComID'));
      this.ComName = localStorage.getItem('ComName');
      this.Region = localStorage.getItem('Region');

      if(this.ComId > 0){
        this.apiservice.modelHomes(this.ComId).subscribe(data => {
          if (data['status_code'] != 200) {
            this.globalService.showError(data['status_message']);
          }
          this.modelHomes = data['finalResult'];
          if((this.modelHomes).length <= 0){
            let loaderService = this.LoadingScreenService;
            loaderService.display(false);
          }
        });
      } else {
        var r = this.router;
        this.globalService.showError('Opps ! we could not get the community');
        setTimeout(function(){
          r.navigate(['/']);
        }, 3000);
      }
    }
    modelHomesGallery(ModelId: any) {
      localStorage.setItem('MHModelId', ModelId);
      /*localStorage.setItem('AHMrDetails', MrDetails);
      localStorage.setItem('AHPrice', Price);
      localStorage.setItem('AHBed', Bed);
      localStorage.setItem('AHBath', Bath);
      localStorage.setItem('AHPar', Par);
      localStorage.setItem("AHSoldAlt", SoldAlt);*/
      this.router.navigate(['model-homes-gallery/']);
    }
  ngOnInit() {
    this.nav.show();
  }
  finalImageLoaded() {
    let loaderService = this.LoadingScreenService;
    loaderService.display(false);
  }

}
