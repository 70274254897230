import { Component, OnInit, AfterViewInit } from '@angular/core';
import { ApiService } from '../services/api.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { InlineSVGModule } from 'ng-inline-svg';
import { PinchZoomModule } from 'ngx-pinch-zoom';

import { GlobalService } from '../services/global.service';
import { LoadingScreenService } from "../services/loading-screen.service";
import { NavbarService } from '../services/navbar.service';

@Component({
  selector: 'app-notebook',
  templateUrl: './notebook.component.html',
  styleUrls: ['./notebook.component.css']
})
export class NotebookComponent implements OnInit, AfterViewInit {

  ComId: number;
  ComName: string;
  Region: string;

  noteBook: any;
  Result: any;

  constructor(
    private apiservice: ApiService,
    private router: Router,
    private globalService: GlobalService,
    private LoadingScreenService: LoadingScreenService,
    public nav: NavbarService
  ) {
    /** Loader start */
    let loaderService = this.LoadingScreenService;
    loaderService.display(true);

    this.ComId = Number(localStorage.getItem('ComID'));
    this.ComName = localStorage.getItem('ComName');
    this.Region = localStorage.getItem('Region');
    var r = this.router;
    if (this.ComId > 0) {
      this.apiservice.noteBook(this.ComId).subscribe(data => {
        if (data['status_code'] != 200) {
          this.globalService.showError(data['status_message']);
        }
        this.noteBook = data['finalResult'].file_name;

        if(this.noteBook == '' || this.noteBook == null){
          this.Result = [];
        } else {
          this.Result = data['finalResult'];
        }
        /** Stop Loader */
        let loaderService = this.LoadingScreenService;
        loaderService.display(false);
      });
    } else {
      this.globalService.showError('Opps! we could not get the community');
      setTimeout(function () {
        r.navigate(['/']);
      }, 3000);
    }
  }
  ngOnInit() {
    this.nav.show();
  }
  ngAfterViewInit(): void {

  }

}
