import { Component, OnInit, AfterViewInit } from '@angular/core';
import { ApiService } from '../services/api.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { LoadingScreenService } from "../services/loading-screen.service";
import { GlobalService } from '../services/global.service';
import { Subscription } from "rxjs";
import { NavbarService } from '../services/navbar.service';

@Component({
  selector: 'app-gallery',
  templateUrl: './gallery.component.html',
  styleUrls: ['./gallery.component.css']
})

export class GalleryComponent implements OnInit, AfterViewInit {
  ComId: number;
  ComName: string;
  Region: string;

  communityGallery: any;

  constructor(
    private apiservice: ApiService,
    private router: Router,
    private globalService: GlobalService,
    private LoadingScreenService: LoadingScreenService,
    public nav: NavbarService
  ) {
    /** Loader start */
    let loaderService = this.LoadingScreenService;
    loaderService.display(true);

    localStorage.setItem("currentPage", this.router.url); /** for set current Page url for filter navigation */

    this.ComId = Number(localStorage.getItem('ComID'));
    this.ComName = localStorage.getItem('ComName');
    this.Region = localStorage.getItem('Region');

    var r = this.router;
    if (this.ComId > 0) {
      this.apiservice.gallery(this.ComId).subscribe(data => {
        if (data['status_code'] != 200) {
          this.globalService.showError(data['status_message']);
        }
        this.communityGallery = data['finalResult'];
        if ((this.communityGallery).length <= 0) {
          let loaderService = this.LoadingScreenService;
          loaderService.display(false);
        }
      });
    } else {
      this.globalService.showError('Opps! we could not get the community');
      setTimeout(function () {
        r.navigate(['/']);
      }, 3000);
    }
  }

  ngOnInit() {
    this.nav.show();
  }
  ngAfterViewInit(): void {

  }
  finalImageLoaded() {
    let loaderService = this.LoadingScreenService;
    loaderService.display(false);
  }
}


