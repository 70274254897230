import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { VideoGalleryComponent } from './video-gallery/video-gallery.component';
import { HeaderComponent } from './header/header.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AutocompleteCompComponent } from './autocomplete-comp/autocomplete-comp.component';
import { ReactiveFormsModule,FormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatFormFieldModule } from '@angular/material';
import { MatInputModule } from '@angular/material';
import { MatTabsModule} from '@angular/material/tabs';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { NgxSliderModule } from "@angular-slider/ngx-slider";  

import { LandingComponent } from './landing/landing.component';
import { SearchByCityComponent } from './search-by-city/search-by-city.component';
import { NavBarComponent } from './nav-bar/nav-bar.component';
import { CommunityDetailsComponent } from './community-details/community-details.component';
import { HomeListComponent } from './home-list/home-list.component';
import { ModelHomesComponent } from './model-homes/model-homes.component';
import { NgSlimScrollModule, SLIMSCROLL_DEFAULTS } from 'ngx-slimscroll';
import { AvailableHomesDetailComponent } from './available-homes-detail/available-homes-detail.component';
import { SimilarHomesComponent } from './similar-homes/similar-homes.component';
import { GalleryComponent } from './gallery/gallery.component';
import { SearchByCountryComponent } from './search-by-country/search-by-country.component';
import { HomeDetailsComponent } from './home-details/home-details.component';
import { MapComponent } from './map/map.component';
import { ContactComponent } from './contact/contact.component';
import { SuccessAlertComponent } from './success-alert/success-alert.component';
import { NotebookComponent } from './notebook/notebook.component';
import { AutofocusDirective } from './autofocus.directive';
import { HttpClientModule,HTTP_INTERCEPTORS } from "@angular/common/http";
import { HttpErrorInterceptor } from './services/http-error.interceptor';
//import { LoadingScreenComponent } from './loading-screen/loading-screen.component';
import { ToastrModule } from 'ngx-toastr';
import { InlineSVGModule } from 'ng-inline-svg';
import { PinchZoomModule } from 'ngx-pinch-zoom';
import { MatRippleModule} from '@angular/material/core';
import { NotFoundComponentComponent } from './not-found-component/not-found-component.component';
import { ModelHomeGalleryComponent } from './model-home-gallery/model-home-gallery.component';
import { ModelHomeDetailsComponent } from './model-home-details/model-home-details.component';
import { CommunityListComponent } from './community-list/community-list.component';
import { CommunityFilterComponent } from './community-filter/community-filter.component';


@NgModule({
  declarations: [
    AppComponent,
    VideoGalleryComponent,
    HeaderComponent,
    AutocompleteCompComponent,
    LandingComponent,
    SearchByCityComponent,
    NavBarComponent,
    CommunityDetailsComponent,
    HomeListComponent,
    ModelHomesComponent,
    AvailableHomesDetailComponent,
    SimilarHomesComponent,
    GalleryComponent,
    SearchByCountryComponent,
    HomeDetailsComponent,
    MapComponent,
    ContactComponent,
    SuccessAlertComponent,
    NotebookComponent,
    AutofocusDirective,
    NotFoundComponentComponent,
    ModelHomeGalleryComponent,
    ModelHomeDetailsComponent,
    CommunityListComponent,
    CommunityFilterComponent,
    //LoadingScreenComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    FormsModule,
    MatAutocompleteModule,
    MatInputModule,
    MatFormFieldModule,
    NgSlimScrollModule,
    // import HttpClientModule after BrowserModule.
    HttpClientModule,
    MatRippleModule,
    MatTabsModule,
    MatCheckboxModule,
    NgxSliderModule,
    ToastrModule.forRoot({
      timeOut: 5000,
      positionClass: 'toast-top-center',
      preventDuplicates: true,
      closeButton:true,
      progressBar:true,
      maxOpened: 1,
      autoDismiss: true,
      tapToDismiss: false
    }),
    InlineSVGModule.forRoot(),
    PinchZoomModule
  ],
  providers: [ {
      provide: SLIMSCROLL_DEFAULTS,
      useValue: {
        alwaysVisible: false,
        gridOpacity: '0.2', barOpacity: '0.5',
        gridBackground: '#ddd',
        gridWidth: '6',
        gridMargin: '2px 2px',
        barBackground: '#F26D78',
        barWidth: '6',
        barMargin: '2px 2px'

      }
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpErrorInterceptor,
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
