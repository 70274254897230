import { Component, OnInit } from '@angular/core';
import { ApiService } from '../services/api.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { GlobalService } from '../services/global.service';
import { LoadingScreenService } from "../services/loading-screen.service";
import { NavbarService } from '../services/navbar.service';
import { Options, LabelType } from '@angular-slider/ngx-slider';

@Component({
  selector: 'app-community-filter',
  templateUrl: './community-filter.component.html',
  styleUrls: ['./community-filter.component.css']
})
export class CommunityFilterComponent implements OnInit {

  minValue: number = 420;
  maxValue: number = 540;
  options: Options = {
    floor: 325,
    ceil: 625,
    translate: (value: number): string => {
      return '$' + value + 'k';
    }
  };

  constructor(
    private apiservice: ApiService,
    private router: Router,
    private globalService: GlobalService,
    private LoadingScreenService: LoadingScreenService,
    public nav: NavbarService
  ) { }

  ngOnInit() {
    this.nav.show();
  }


}
