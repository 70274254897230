import { Component, OnInit, AfterViewInit, ChangeDetectorRef } from '@angular/core';
import { ApiService } from '../services/api.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';

import { GlobalService } from '../services/global.service';
import { LoadingScreenService } from "../services/loading-screen.service";
import { NavbarService } from '../services/navbar.service';

@Component({
  selector: 'app-community-details',
  templateUrl: './community-details.component.html',
  styleUrls: ['./community-details.component.css']
})
export class CommunityDetailsComponent implements OnInit, AfterViewInit {

  ComId: number;

  ComName: string;
  Overview: string;
  Phone: string;
  Region: string;
  Price: any;

  constructor(
    public cdRef: ChangeDetectorRef,
    private apiservice: ApiService,
    private router: Router,
    private globalService: GlobalService,
    private LoadingScreenService: LoadingScreenService,
    public nav: NavbarService
  ) {

    /** Loader Start */
    let loaderService = this.LoadingScreenService;
    loaderService.display(true);


    /** for set current Page url for filter navigation */
    localStorage.setItem("currentPage", this.router.url);

    this.ComId = Number(localStorage.getItem('ComID'));
    this.ComName = localStorage.getItem('ComName');
    this.Region = localStorage.getItem('Region');
  }

  ngOnInit() {
    this.nav.show();
    if (this.ComId > 0) {
      this.apiservice.communityContact(this.ComId).subscribe(data => {
        if (data['status_code'] != 200) {
          this.globalService.showError(data['status_message']);
        }
        this.Overview = (data['finalResult'].overview).replace(/<[^>]+>/g, '').replace(/&nbsp;/g, ' ');
        this.Phone = (data['finalResult'].phone);
        this.Price = Math.round(data['finalResult'].start_price) + ',000';
      });
    } else {
      var r = this.router;
      this.globalService.showError('Opps ! we could not get the community');
      setTimeout(function () {
        r.navigate(['/']);
      }, 3000);
    }

  }
  ngAfterViewInit(): void {
    this.cdRef.detectChanges();
    let loaderService = this.LoadingScreenService;
    loaderService.display(false);
  }
}
