import { Component, OnInit, AfterViewInit } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { ApiService } from '../services/api.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';

import { LoadingScreenService } from "../services/loading-screen.service";
import { NavbarService } from '../services/navbar.service';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css']
})


export class ContactComponent implements OnInit, AfterViewInit {
  //public contactForm: FormGroup;
  submitted = false;

  ComId: number;
  ComName: string;
  Region: string;
  prevPage: string;

  constructor(
    private fb: FormBuilder,
    private apiservice: ApiService,
    private router: Router,
    private LoadingScreenService: LoadingScreenService,
    public nav: NavbarService
  ) {

    /** Loader start */
    let loaderService = this.LoadingScreenService;
    loaderService.display(false);

    this.prevPage = localStorage.getItem("currentPage");
    if(this.prevPage == '' || this.prevPage == null || this.prevPage == 'null' || this.prevPage == 'undefined'){
      this.prevPage = 'community-list/';
    }
  }
  contactForm = this.fb.group({
    name: ['', Validators.required],
    email: ['', [Validators.required, Validators.email] ],
    message: ['', [Validators.required]],
    phone: []
  });
  ngOnInit() {
    this.nav.show();
  }
  ngAfterViewInit(): void {
    /** Loader Stop */
    let loaderService = this.LoadingScreenService;
    loaderService.display(false);
  }
  get f() { return this.contactForm.controls; }
  onSubmit() {
    this.submitted = true;
    if (this.contactForm.invalid) {
        return;
    }
    this.apiservice.contact(this.contactForm.value).subscribe(data => {
      if(data['status'] == 'success'){
        this.router.navigate(['success-alert/']);
      }
    });
  }
}


