import { Injectable, Injector } from '@angular/core';
import {
 HttpEvent,
 HttpInterceptor,
 HttpHandler,
 HttpRequest,
 HttpResponse,
 HttpErrorResponse
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { LoadingScreenService } from "../services/loading-screen.service";
import { finalize } from "rxjs/operators";
import { interval} from "rxjs";
import { Router } from '@angular/router';

@Injectable({ providedIn: 'root' })
export class HttpErrorInterceptor implements HttpInterceptor {
  activeRequests: number = 0;
  skippUrls = [
    'AssumeRole'
  ];
 constructor(private injector: Injector,private loadingScreenService: LoadingScreenService,private router:Router) { }
 intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
  let displayLoadingScreen = true;
      request = request.clone(); //{ headers: request.headers.set('Content-Type', 'application/json') }
  for (const skippUrl of this.skippUrls) {
    if (new RegExp(skippUrl).test(request.url)) {
      displayLoadingScreen = false;
      break;
    }
  }

  if (displayLoadingScreen) {
    if (this.activeRequests === 0) {
      this.loadingScreenService.startLoading();
    }
    this.activeRequests++;
   return next.handle(request)
     .pipe(
       retry(1),
       finalize(() => {
        this.activeRequests--;
        if (this.activeRequests === 0) {
          this.loadingScreenService.stopLoading();
        }
      }),
       catchError((error: HttpErrorResponse) => {
        const toastr = this.injector.get(ToastrService);
         let errorMessage = '';
         if (error.error instanceof ErrorEvent) {
           // client-side error
           errorMessage = `Error: ${error.error.message}`;
         } else {
           // server-side error
           errorMessage = 'Server side error, Please try some times later';
           if(error.status == 400) {
            errorMessage = 'Server side error, Please try some times later';
           } else if(error.status == 401) {
            errorMessage = error.message;
           }
         }
         toastr.error(errorMessage,'Failure');
         return throwError(errorMessage);
       })
     )
    } else {
      return next.handle(request);
    }
 }
}
